$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-nav-item-parent").mouseenter(function(){
        $(this).siblings().find("ul").removeClass("active");
        $(this).find("a").addClass("active");
        $(this).find("ul").addClass("active");
        $(".js-header").addClass("open");
    });

    $(".js-nav-item-parent").mouseleave(function(){
        $(this).find("ul").removeClass("active");
        $(this).find("a").removeClass("active");
        $(".js-header").removeClass("open");
    });

    $(".js-nav-item").mouseenter(function(){
        $(".js-header").addClass("active");
    });

    $(".js-nav-item").mouseleave(function(){
        $(".js-header").removeClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header
    // *
    // *
    $(window).on("load scroll", function(){
        if($(window).scrollTop() >= 30) {
			$(".js-header").addClass("sticky");
		}else {
			$(".js-header").removeClass("sticky");
		}
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * view port
	// *
	// *
	var offset = 80; /* Abstand vom Browserbottom, wenn Effekt einsetzen soll */

	function inViewpoint(){
		var viewPort = $(window).scrollTop() + $(window).height();

		$(".viewpoint-element:visible").each(function(){
			var elementTop = $(this).offset().top;

			if ((elementTop + offset) <= viewPort) {
				var delay = $(this).data("delay");

				// check for fact class - if in viepoint fire countUp (once)
				if ($(this).hasClass("js-count-up") && !$(this).hasClass("in-viewpoint")) {
					countUp();
				}

				$(this).addClass("in-viewpoint");
			}
		});
	}
	$(window).scroll(function() {
		inViewpoint();
	});
	inViewpoint();



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * countUp
	// *
	// *
	function countUp() {

		// count up
		var options = {
		  useEasing: true,
		  useGrouping: true,
		  separator: '.',
		  decimal: '.',
		};

		$(".js-count-up").each(function(){
			var counterID = $(this).attr("id");
				countTo = $(this).data("count");
			var count = new CountUp(counterID, 0, countTo, 0, 4, options);
			count.start();
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * instagram
    // *
    // * Docs: https://github.com/scottalguire/grouploop
    var $instagram = $(".js-instagram");

    if ($instagram.length){
        $instagram.grouploop({
            velocity: 0.5,
            forward: false,
            childNode: ".js-instagram-item",
            childWrapper: ".js-instagram-wrapper",
            pauseOnHover: true
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav mobile
    // *
    // *
    $(".js-nav-mobile-button").click(function () {
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    })

    $(".js-nav-mobile-toggle").click(function () {
        $(this).toggleClass("open");
        $(this).siblings().slideToggle();
    })

    $(".js-nav-mobile-toggle.active").trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * teamCarousel
	// *
	// *
	var $teamCarousel = $(".js-team-carousel");

	if ($teamCarousel.length){
		$teamCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: true,
				infinite: true,
				arrows: true,
				autoplay: false,
				speed: 1000,
                slidesToShow: 4,
				prevArrow: $(this).parents(".js-team-carousel-wrapper").find(".js-team-carousel-nav-prev"),
				nextArrow: $(this).parents(".js-team-carousel-wrapper").find(".js-team-carousel-nav-next"),
                responsive: [
                        {
                          breakpoint: 1200,
                          settings: {
                            slidesToShow: 3,
                          }
                        },
                        {
                          breakpoint: 900,
                          settings: {
                            slidesToShow: 2,
                          }
                        }
                    ]
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slider
	// *
	// *
	var $slider = $(".js-slider");

	if ($slider.length){
		$slider.each(function(){
            var $sliderImages = $(this).find(".js-slider-images"),
                $sliderContent = $(this).find(".js-slider-content");
                total = $sliderContent.find(".slider__content").length;

			$sliderImages.slick({
				fade: false,
				dots: false,
				infinite: false,
				arrows: true,
				autoplay: false,
				speed: 1000,
                adaptiveHeight: true,
				prevArrow: $(this).find(".js-slider-nav-prev"),
				nextArrow: $(this).find(".js-slider-nav-next"),
                asNavFor: $sliderContent
			});

			$sliderContent.slick({
				fade: true,
				dots: false,
				infinite: false,
				arrows: false,
				autoplay: false,
				speed: 1000,
                asNavFor: $sliderImages
			});

            // set total
            $(this).find(".js-slider-total").html(total);

            // set current count
            $sliderImages.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $slider.find(".js-slider-current").html((nextSlide + 1));
            });
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * fancybox
	// *
	// *
	$('[data-fancybox]').fancybox({
		buttons: [
			"close"
		  ]
	});



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * faqs
	// *
	// *
    $(".js-faq-tab-button").click(function(){
        // reset accordions after click
        $(".js-faq-accordion-button").removeClass("active");
        $(".js-faq-accordion-content").slideUp();

        // trigger different faqs
        var index = $(this).index();
        $(this).addClass("active").siblings().removeClass("active");
        $(".js-faq-accordion:nth-child(" + (index + 1) + ")").show().siblings().hide();
    });

    // trigger first tab on load
    $(".js-faq-tab-button:nth-child(1)").trigger("click");

    // faq accordion
    $(".js-faq-accordion-button").click(function () {
        $(this).toggleClass("active").siblings().slideToggle().parent(".js-faq-item").siblings().find(".js-faq-accordion-button").removeClass("active").siblings(".js-faq-accordion-content").slideUp();
    })


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * faq - Filtering/Searching
    // *
    // *
    var $projectSearchList = $(".js-faq-accordion");

    jQuery.expr[':'].Contains = function(a,i,m){
       return (a.textContent || a.innerText || "").toUpperCase().indexOf(m[3].toUpperCase())>=0;
    };

    $(".js-faq-search").change(function (){
       var filter = $(this).val();
       $(".js-faq-accordion").show();
       $(".js-faq-tab-button").removeClass("active");
       $projectSearchList.find(".js-faq-item:not(:Contains(" + filter + "))").hide();
       $projectSearchList.find(".js-faq-item:Contains(" + filter + ")").show();
    }).keyup(function () {
       // fire the above change event after every letter
       $(this).change();
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * popup
	// *
	// *
    $(".js-popup-button").click(function(){
        $(".js-header").toggle();
        $(".js-popup").fadeToggle();
        $("body, html").toggleClass("freeze");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggle filter button
    // *
    // *
    $(".js-filter-button").click(function(event){
        $(this).toggleClass("active").siblings().removeClass("active");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * js-copy-paste
	// *
	// *
    $(".js-copy").click(function() {
        $(this).parents(".js-copy-paste").find(".js-paste").html($(this).html());
    })

    $(".js-copy-button").click(function() {
        $(this).parents(".js-copy-paste").find(".js-paste").html($(this).parents(".js-copy-parent").find(".js-copy").html());
    })


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * shuffle gallery
    // *
    // *
    var Shuffle = window.Shuffle;

    var $filter = $(".js-filter-items");

	if ($filter.length){
		$filter.each(function(){

            var shuffleInstance = new Shuffle($('.js-filter-items'), {
              itemSelector: '.js-filter-item',
              sizer: '.js-filter-item'
            });

            $('.js-filter-button').on('click',function(e){
                e.preventDefault();
                $(this).addClass('active').siblings().removeClass('active');
                var keyword = $(this).attr('data-group');
                shuffleInstance.filter(keyword);
            });

            $('.js-filter-button:nth-child(1)').trigger("click");

        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * shuffle bulletpoints
    // *
    // *
    var $bulletPoints = $(".js-bullet-point-wrapper");

	if ($bulletPoints.length){
		$bulletPoints.each(function(){
            var shuffleInstance = new Shuffle($(this).find('.js-bullet-points'), {
              itemSelector: '.js-bullet-point',
              sizer: '.js-bullet-point'
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * event date
    // *
    // *
    $(".js-eventdate-location").on("change", function(){
        var locationId = $(this).val();

        // hide all date buttons
        $(".js-eventdate").hide();

        // show filtered event buttons
        $(".js-eventdate-filter-location-" + locationId).show();

        // activate date buttons
        $(".js-eventdate").removeClass("inactive");

    });

    $(".js-eventdate").on("change", function(){
        // activate payment buttons
        $(".js-eventdate-payment").removeClass("inactive");
    });

    $(".js-eventdate-payment").on("change", function(){
        // activate payment buttons
        $(".js-eventdate-button").prop('disabled', false);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * event date too soon
    // *
    // *
    $(".js-date").on("click", function(){
        if($(this).data("date-too-soon") === 1){
            $(".js-too-soon-alert").addClass("active")
            $(".js-too-soon-course").addClass("hide")
        }
        else{
            $(".js-too-soon-alert").removeClass("active")
            $(".js-too-soon-course").removeClass("hide")
        }

       
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contactForm
    // *
    // *
    $(".js-contactform-category").on("change", function(){
        var category = $(this).val();

        if (!category) return;

        $(".js-contactform-location option").hide().removeAttr("selected");
        $('.js-contactform-location [data-category="' + category +'"]').show();
    });

    $(".js-contactform-location").on("change", function(){
        var location = $(this).val(),
            category = $(this).find(":selected").data("category");

        if (!category) return;

        $(".js-contactform-date option").hide().removeAttr("selected");
        $('.js-contactform-date [data-category="' + category +'"][data-location="' + location +'"]').show();
    });

    // hide options if fields are already selected after refresh
    if ($(".js-contactform-category").val()) {
        console.log('test');
        var category = $(".js-contactform-category").val(),
            loc = $(".js-contactform-location").val(),
            date = $(".js-contactform-date").val();

        $(".js-contactform-category").val(category).change();
        $(".js-contactform-location:visible").val(loc).change();
        $(".js-contactform-date:visible").val(date).change();
    }else {
        $(".js-contactform-date option").hide();
        $(".js-contactform-location option").hide();
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * ratingCarousel
	// *
	// *
	var $ratingCarousel = $(".js-rating-carousel");

	if ($ratingCarousel.length){
		$ratingCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: true,
				infinite: true,
				arrows: true,
				autoplay: true,
                autoplaySpeed: 4000,
				speed: 1000,
                slidesToShow: 3,
				prevArrow: $(this).parents(".js-rating-carousel-wrapper").find(".js-rating-carousel-nav-prev"),
				nextArrow: $(this).parents(".js-rating-carousel-wrapper").find(".js-rating-carousel-nav-next"),
                responsive: [
                        {
                          breakpoint: 1100,
                          settings: {
                            slidesToShow: 2,
                          }
                        },
                        {
                          breakpoint: 700,
                          settings: {
                            slidesToShow: 1,
                          }
                        }
                    ]
			});
		});
	}

    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * modal
	// *
	// *
    if ($(".js-modal").length) {
        var popup = sessionStorage.getItem("popup");

        if (popup != 1) {
            $(".js-modal, .js-modal-close").fadeIn();
            $("body, html").addClass("freeze");
        }

        $(".js-modal-close").click(function(){
            $(".js-modal, .js-modal-close").fadeOut();
            $("body, html").removeClass("freeze");
            sessionStorage.setItem("popup",1);
        });
    }


});
